.btn {
  font-size: $fs-l;
  border-radius: $br-button;
  font-weight: $fw-light;
  transition: all 0.5s ease-in;
  padding: 12px 14px 12px;
  line-height: 1;

  @media screen and (min-width: $breakpoint-md) {
    font-size: $fs-xl;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    font-size: $fs-xxl;
  }

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &__180 {
    width: 180px;
  }

  &__420 {
    width: 100%;
    max-width: 420px;
  }

  &__border {
    border-width: 1px;
    border-style: solid;
    line-height: 1;

    &:focus {
      border-width: 1px;
      border-style: solid;
    }

    &_primary {
      background: linear-gradient(
        to right,
        transparent 0%,
        transparent 50%,
        $color-primary-light 50%,
        $color-primary-light 100%
      );
      background-size: 200% 100%;
      background-position: 0%;
      border-color: $color-primary-base;
      color: $color-primary-base;
      padding: 8px 24px 8px;

      img {
        width: 16px;
        height: 16px;
      }

      &:hover {
        border-color: $color-primary-light;
        background-position: 100%;
        color: $color-primary-base;
      }

      @media screen and (min-width: $breakpoint-md) {
        font-size: $fs-xl;
        padding: 8px 24px 6px;

        img {
          width: 24px;
          height: 24px;
        }

        border-width: 2px;

        &:focus {
          border-width: 2px;
        }
      }

      @media screen and (min-width: $breakpoint-xl) {
        border-width: 3px;

        &:focus {
          border-width: 3px;
        }
      }

      &_small {
        border-width: 2px;

        &:focus {
          border-width: 2px;
        }
      }

      &__white {
        border-color: $color-white;
        color: $color-white;
      }

      &:focus {
        border-color: $color-primary-base;
        color: $color-primary-base;
      }
    }

    &_white {
      background: linear-gradient(
        to right,
        transparent 0%,
        transparent 50%,
        $color-primary-light 50%,
        $color-primary-light 100%
      );
      background-size: 205% 100%;
      background-position: 0%;
      border-color: $color-white;
      color: $color-white;
      border-width: 1.5px;
      font-size: $fs-l;
      border-radius: 999px;
      padding: 10px 15px 10px;

      &:hover {
        border-color: $color-primary-light;
        background-position: 100%;
        color: $color-primary-base;
      }

      @media screen and (min-width: $breakpoint-md) {
        padding: 20px 25px;
        font-size: $fs-xxxl;
      }

      @media screen and (min-width: $breakpoint-lg) {
        padding: 10px 15px 10px;
        font-size: $fs-xl;
      }

      @media screen and (min-width: $breakpoint-xl) {
        padding: 20px 25px;
        font-size: $fs-xxl;
      }

      @media screen and (min-width: $breakpoint-xxl) {
        font-size: $fs-xxxl;
      }
      &:focus {
        border-color: $color-white;
        color: $color-white;
      }
    }

    &_white_2 {
      background: linear-gradient(
        to right,
        transparent 0%,
        transparent 50%,
        $color-primary-light 50%,
        $color-primary-light 100%
      );
      background-size: 205% 100%;
      background-position: 0%;
      border-color: $color-white;
      color: $color-white;
      border-width: 3px;
      font-size: $fs-l;
      border-radius: 999px;
      padding: 10px 15px 10px;
      text-transform: uppercase;

      &:hover {
        border-color: $color-primary-light;
        background-position: 100%;
        color: $color-primary-base;
      }

      @media screen and (min-width: $breakpoint-md) {
        padding: 16px 25px;
        font-size: $fs-xxxl;
      }

      @media screen and (min-width: $breakpoint-lg) {
        padding: 10px 15px 10px;
        font-size: $fs-xl;
      }

      @media screen and (min-width: $breakpoint-xl) {
        padding: 16px 25px;
        font-size: $fs-xxl;
      }

      &:focus {
        border-color: $color-white;
        color: $color-white;
      }
    }
  }

  &__fill {
    border: none;
    &_primary {
      background-color: $color-primary-light;
      color: $color-primary-base;

      &:hover {
        background-color: $color-primary-light;
        color: $color-primary-base;
      }

      &:focus,
      &:active {
        background-color: $color-primary-light;
        color: $color-primary-base;
      }

      @media screen and (min-width: $breakpoint-xl) {
        background: linear-gradient(
          to right,
          $color-white 0%,
          $color-white 50%,
          $color-primary-light 50%,
          $color-primary-light 100%
        );
        background-size: 200% 100%;
        background-position: 0%;
        transition: all 0.5s ease-in !important;

        &:focus {
          background-color: $color-white;
          color: $color-primary-base;
        }

        &:hover {
          background-position: 100%;
          color: $color-primary-base;
        }

        &:active {
          background-color: $color-primary-light !important;
          color: $color-primary-base !important;
        }
      }

      &_static {
        background-color: $color-primary-light;
        color: $color-primary-base;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    &_solid {
      background: linear-gradient(
        to right,
        $color-primary-light 0%,
        $color-primary-light 50%,
        $color-primary-base 50%,
        $color-primary-base 100%
      );
      background-size: 200% 100%;
      background-position: 0%;
      color: $color-primary-base;
      display: inline-block;
      transition: all 0.5s ease-in !important;

      &:hover {
        background-position: 100%;
        color: $color-primary-light;
      }

      &:active {
        background-color: $color-primary-base !important;
        color: $color-primary-light !important;
      }

      &_primary {
        background: linear-gradient(
          to right,
          $color-primary-base 0%,
          $color-primary-base 50%,
          $color-primary-light 50%,
          $color-primary-light 100%
        );
        background-size: 200% 100%;
        background-position: 0%;
        color: $color-primary-light;

        @media screen and (min-width: $breakpoint-xl) {
          position: absolute;
        }
        &:hover {
          background-position: 100%;
          color: $color-primary-base;
        }
      }
    }
  }

  &__full {
    width: 100%;

    @media screen and (min-width: $breakpoint-md) {
      width: auto;
    }
  }

  &__big {
    font-size: $fs-xl;

    @media screen and (min-width: $breakpoint-md) {
      font-size: $fs-xxl;
    }

    @media screen and (min-width: $breakpoint-xl) {
      font-size: $fs-xxxl;
    }
  }

  &__mobile {
    @media screen and (max-width: $breakpoint-lg-max) {
      background-color: $color-primary-light;
      border-color: $color-primary-light;
    }
  }
}

.whatsUpBTN {
  position: fixed;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 60;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 9px;

  svg {
    width: 64px;
    height: 64px;
  }

  &__bg {
    fill: $color-primary-light;

    transition: all 0.5s ease-in;
  }

  &__icon {
    fill: $color-primary-base;
    transition: all 0.5s ease-in;
  }

  &__popup {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 7px;
  }

  @media screen and (min-width: $breakpoint-lg) {
    bottom: 24px;
    right: 24px;

    svg {
      width: 74px;
      height: 74px;
    }
    //   &:hover {
    //     .whatsUpBTN__bg {
    //       fill: rgba($color-primary-light, 0.5);
    //     }
    //   }
  }
}
